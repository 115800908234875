import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cus_button = _resolveComponent("cus-button")

  return (_openBlock(), _createBlock(_component_cus_button, null, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextNew), 1)
    ]),
    _: 1
  }))
}