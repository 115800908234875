
import {
    defineComponent, nextTick, onMounted, PropType,
    ref, Ref, watch
} from 'vue';
import { isElement } from '@/methods/basicFun';

interface NavItem {
    label: string;
    key: string;
    path: string;
}
export default defineComponent({
    props: {
        navs: {
            required: true,
            type: Array as PropType<Array<NavItem>>
        },
        active: {
            required: true,
            type: String
        },
        resetWidth: {
            type: Number
        }
    },
    setup(props, { emit }) {
        const navContent: Ref< HTMLElement | null > = ref(null);
        const choose = (navItem: NavItem) => {
            emit('choose', navItem.key, navItem.path);
        };
        const contentWidth = ref('');
        const getContentWidth = () => {
            nextTick(() => {
                if (isElement(navContent.value)) {
                    contentWidth.value = navContent.value.getBoundingClientRect().width.toFixed(2);
                }
            });
        };
        onMounted(() => {
            getContentWidth();
        });
        watch(() => props.active, () => {
            getContentWidth();
        });

        return {
            choose,
            navContent,
            contentWidth
        };
    }
});
