
import { defineComponent, reactive, ref } from 'vue';

export default defineComponent({
    setup(props, { emit }) {
        const formEle = ref();
        const formData = reactive({
            Password: ''
        });

        const rules = {
            Password: [{ required: true, message: WordList.RulePasswordEmpty, trigger: 'blur' }]
        };

        const submit = () => {
            formEle.value.validate((valid: boolean) => {
                if (valid) {
                    emit('submit', formData);
                }
            });
        };

        return {
            formEle,
            formData,
            rules,
            submit
        };
    }
});
