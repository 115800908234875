
import { defineComponent, PropType } from 'vue';
import SecondaryNav from '@/components/common/secondary-nav/index.vue';
import PropertyBreadCrumb from '@/components/view/pm/bread-crumb';
import initData from '@/views/pm/old-community/staff-auth/init-data';
import initRfCard from '@/views/pm/old-community/staff-auth/init-rf-card';
import AddButton from '@/components/common/add-button/index.vue';
import { listPagination } from '@/components/common/list';
import DeleteDialog from '@/components/view/pm/delete-all-dialog';

export default defineComponent({
    components: {
        SecondaryNav,
        PropertyBreadCrumb,
        AddButton,
        listPagination,
        DeleteDialog
    },
    props: {
        type: {
            type: String as PropType<'rfCard'>,
            default: 'rfCard'
        }
    },
    setup(props) {
        const propsType = props.type;
        const {
            breadHeaders,
            navs,
            activeNavItem,
            changePath
        } = initData(propsType);

        const {
            searchKeyList,
            searchList,
            saveParamInPath,
            requestData,
            updateList,
            add,
            edit,
            importRfCard,
            deleteAll,
            download,
            headers,
            totalCount,
            isShowDeleteDialog,
            file,
            form,
            setFile
        } = initRfCard();

        return {
            breadHeaders,
            navs,
            activeNavItem,
            changePath,
            searchKeyList,
            searchList,
            saveParamInPath,
            requestData,
            updateList,
            add,
            edit,
            importRfCard,
            deleteAll,
            download,
            headers,
            totalCount,
            isShowDeleteDialog,
            file,
            form,
            setFile
        };
    }
});
